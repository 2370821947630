<ng-container *transloco="let t">
  @if(showFooter()) {
    <footer class="pt-[80px] pb-[40px] px-[20px]">
      <div class="footer-content">
        <div class="social flex flex-col flex-1 gap-[24px]">
          <img src="/assets/images/in-time-main-logo.png" class="mb-[40px] w-[160px]">
          <h2 class="title">{{ t('footer.social') }}</h2>
          <div class="flex flex-row gap-[10px]">
            <a href="https://facebook.com/in.time.booking" target="_blank"><img class="mr-[10px] w-[35px]" src="assets/svg/facebook_icon.svg"></a>
            <a href="https://instagram.com/in.time.booking" target="_blank"><img class="mr-[10px] w-[35px]" src="assets/svg/instagram-icon.svg"></a>
          </div>
          <div class="language-select flex flex-row items-center gap-[5px]">
            <p>{{ t('footer.language') }}:</p>
            <a [matMenuTriggerFor]="languageSelect">
              <span>{{ currentLocale().displayName }}</span>
              <img src="/assets/svg/chevron-down-blue.svg">
            </a>
            <mat-menu #languageSelect="matMenu">
              @for(locale of locales; track locale.name) {
                <button mat-menu-item class="language" (click)="changeLocale(locale)">
                  <span class="fp {{ locale.countryCode | lowercase }}"></span>
                  {{ locale.displayName }}
                </button>
              }
            </mat-menu>
          </div>
        </div>
        <div class="terms flex flex-col flex-1 gap-[24px]">
          <h2 class="title">{{ t('footer.legal') }}</h2>
          <div class="flex flex-col gap-[16px]">
            <a routerLink="/cancel-information">{{ t('footer.cancelInformation') }}</a>
            <a routerLink="/privacy-policy">{{ t('footer.privacyPolicy') }}</a>
            <a routerLink="/terms-and-conditions">{{ t('footer.termsAndConditions') }}</a>
            <a routerLink="/business-terms-and-conditions">{{ t('footer.businessTermsAndConditions') }}</a>
            @if(showAnpcLogo()) {
              <a href="https://anpc.ro/" target="_blank">ANPC</a>
            }
          </div>
        </div>
        <div class="info flex flex-col flex-1 gap-[24px]">
          <h2 class="title">{{ t('footer.info') }}</h2>
          <div class="flex flex-col gap-[16px]">
            <div class="phone">
              <h3 class="mb-[5px]">{{ t('customerSupport.phone') }}</h3>
              <div class="method">
                <p class="text-center sm:text-left">HU: <a href="tel:+36703777863" target="_blank">+36 70 377 7863</a></p>
              </div>
              <div class="method">
                <p class="text-center sm:text-left">RO: <a href="tel:+40751249202" target="_blank">+40 751 249 202</a></p>
              </div>
              <div class="method">
                <ng-container *transloco="let t">
                  <p class="text-center sm:text-left">{{ t('customerSupport.availability1') }}</p>
                </ng-container>
              </div>
              <div class="method">
                <ng-container *transloco="let t">
                  <p class="text-center sm:text-left">{{ t('customerSupport.availability2') }}</p>
                </ng-container>
              </div>
            </div>
            <div class="email">
              <h3 class="mb-[5px]">{{ t('customerSupport.email') }}</h3>
              <div class="method">
                <p><a href="mailto:hello@in-time.hu" target="_blank">hello&#64;in-time.hu</a></p>
              </div>
            </div>
            @if(showBarionLogo()) {
              <div class="barion">
                <p class="mb-[10px]">{{ t('footer.barion') }}</p>
                <img class="w-[100%]" src="/assets/images/barion-card-strip-intl__medium.png" />
              </div>
            }
          </div>
        </div>
      </div>
    </footer>
    <div class="copyright">
      <p>Copyright © {{ currentYear }} In Time Business Kft. {{ t('footer.allRightsReserved') }}</p>
    </div>
  }
</ng-container>